import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/general.css';
import AppRoutes from './App.routes';
import { Helmet } from 'react-helmet';
import { KEY_LS_AWS_ENV } from './utility/constants';
import { ConfigProvider } from 'antd';

const App: React.FC = () => {
  const clarityScript = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "nysar8gsqx");`;

  const _setupClarity = () => {
    // Used for production only
    const cloud = localStorage.getItem(KEY_LS_AWS_ENV) ?? '';

    return cloud == '2' ? (
      <></>
    ) : (
      <Helmet>
        <script type="text/javascript">{clarityScript}</script>
      </Helmet>
    );
  };

  const theme = {
    fontFamily: 'Inter, sans-serif',
  };

  return (
    <ConfigProvider
      theme={{
        token: theme,
      }}
    >
      <div className="">
        {_setupClarity()}
        <AppRoutes />
        <ToastContainer position="bottom-right" />
      </div>
    </ConfigProvider>
  );
};

export default App;
