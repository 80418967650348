import React from 'react';
import Sidebar from '../Sidebar';
import { Layout } from 'antd';

export interface IMainLayoutProps {
  children?: React.ReactNode;
  side: boolean; // Show sidebar if true
}

const MainLayout: React.FC<IMainLayoutProps> = (props) => {
  const renderSideBar = (doRender: boolean) => {
    if (doRender) {
      return <Sidebar />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh', position: 'relative' }}>
        {renderSideBar(props.side)}
        {props.children}
      </Layout>
    </>
  );
};

export default MainLayout;
